import { useQuery } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import { processDrupalSearchResponse } from '../utils/helpers';

const apiEndpoint = process.env.GATSBY_DRUPAL_ENDPOINT || '';

const getDrupalSearch = async (
  params: SearchParams,
  language: 'en' | 'es' | (string & {}) = 'en',
) => {
  const langPrefix = language === 'en' ? '' : language;
  const searchPageApiEndpoint = `${apiEndpoint}${langPrefix}/api/search/${
    typeof params.api_slug !== 'undefined' ? params.api_slug : ''
  }`;

  const response = await axios.get(searchPageApiEndpoint, {
    params,
  });

  const formattedParams = {
    ...params,
    api_slug: typeof params.api_slug !== 'undefined' ? params.api_slug : 'products',
  };

  const result = processDrupalSearchResponse({ ...response.data, params: formattedParams });
  result.api_slug = formattedParams.api_slug;
  return result;
};

export const useSearchDrupal = (params: SearchParams, language: 'en' | 'es' | (string & {})) => {
  const fetcher = () => getDrupalSearch(params, language);
  const { data, error, isLoading, isFetching, isRefetching } = useQuery(
    ['searchDrupal', params],
    fetcher,
    {
      staleTime: 3600000,
      refetchOnWindowFocus: false,
      onSuccess: result => {
        const analyticsData = {
          event: 'search',
          eventCategory: 'search bar',
          eventAction: 'search submitted',
          eventLabel: `search term: ${params.keywords ?? ''}`,
          eventParams: {
            search_term: params.keywords ?? '',
          },
        };
        sendIt(analyticsData);
      },
    },
  );

  return { data, error, isLoading, isRefetching, isFetching };
};

type DrupalArticleListingSearch = Omit<DrupalSearch, 'search_results'> & {
  search_results: ArticleSearchResult[];
};

const getArticles = async (params: AxiosRequestConfig) => {
  const response = await axios.get<DrupalArticleListingSearch>(
    `${apiEndpoint}/api/search/articles`,
    {
      headers: {
        'api-key': apiKey,
      },
      params,
    },
  );
  return response.data;
};

export const useSearchArticles = (config: AxiosRequestConfig) =>
  useQuery({
    queryKey: ['getArticles', config],
    queryFn: () => getArticles(config || {}),
  });
