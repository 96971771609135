import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import { mdiCalendar } from '@mdi/js';
import Icon from '@mdi/react';
import { intlFormat } from 'date-fns';
import { PageProps, graphql } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Layout from '../components/Layout';
import Link from '../components/Link';
import Seo from '../components/Seo';
import ArticleOverlayCard from '../components/article_card/ArticleOverlayCard';
import ProductCard from '../components/product_card/ProductCard';
import RichText from '../components/rich_text/RichText';
import { theme } from '../theme/theme';
import { getFocalPoint, getMedia } from '../utils/mediaHelpers';
import { getHelmetPropsFromMetatagProps, getJsonFromMetatagProps } from '../utils/metatagHelpers';

interface IRelatedArticleCards {
  edges: {
    node: IArticleContent;
  }[];
}
interface DataProps {
  node: IArticleContent;
  relatedArticles: IRelatedArticleCards;
  backfillArticles: IRelatedArticleCards;
  translations: Translations;
}

const chip = css`
  background-color: white;
  border: 1px solid #b6bed2;
  border-radius: 0.5rem;
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-left: 1rem;
  padding: 0.5em 1em;
  text-decoration: none;
  text-transform: uppercase;

  &:first-of-type {
    margin-left: 0;
  }

  &:hover {
    border-color: #000000;
    color: #000000;
  }
`;

const relatedLayout = css`
  display: grid;
  gap: 1rem;
  grid-template-columns: 100%;
  grid-template-rows: auto;

  @media (min-width: ${theme.media.md}) {
    grid-template-columns: 1fr 1fr;

    & > :first-child {
      grid-column: 1 / 3;
      grid-row: 1;
    }
  }

  @media (min-width: 960px) {
    grid-template-columns: 66% 1fr;
    grid-template-rows: auto;
    grid-auto-flow: column;

    & > :first-child {
      grid-column: 1;
      grid-row: 1 / 3;

      & > * {
        height: 100%;
      }
    }
  }
`;

const AuthorContainer = styled.div`
  display: flex;
  justify-content: center;
  a {
    color: #000000;
  }
`;
const ArticleInfoContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  @media (min-width: ${theme.media.md}) {
    align-items: end;
    flex-direction: row;
    gap: 1rem;
  }
`;
const DotSeparator = () => {
  return <span aria-hidden="true">&bull;</span>;
};
const locales = { en: 'en', fr: 'fr' };

const getArticleCardProps = (node: IArticleContent) => {
  const { title, drupal_id } = node;
  const summary = node.body.summary ?? undefined;
  const url = node.path?.alias;
  // eslint-disable-next-line
  const image = node.relationships.field_image?.relationships.field_media_image?.gatsbyImage;

  const imageField = node.relationships.field_image;
  const imageAlt = node.relationships.field_image?.field_media_image?.alt ?? '';
  const promoted = node.sticky;

  return {
    title,
    type: 'article_card',
    name: '',
    drupal_id,
    url,
    summary,
    imageComponent: image ? (
      <GatsbyImage
        alt={imageAlt}
        image={image}
        imgStyle={{
          objectFit: 'cover',
        }}
        objectPosition={getFocalPoint(imageField)}
        loading="eager"
      />
    ) : (
      <StaticImage
        aspectRatio={1.5}
        src="../assets/media/images/cat-dog-article-placeholder-2.jpg"
        alt="A cute dog & a cute cat."
      />
    ),
    promoted,
  };
};

const getArticleMedia = (node: IArticleContent, mediaType: string) => {
  let media: { media: any; sizes?: string; loading: 'eager' | 'lazy' } = {
    media: null,
    loading: 'eager',
  };
  switch (mediaType) {
    case 'media__remote_video':
    case 'media__infographic':
      media = {
        media: node.relationships.field_media,
        loading: 'eager',
      };
      break;
    default:
      media = {
        media: node.relationships.field_image,
        sizes:
          '(min-width: 960px) 960px, (min-width: 1200px) 1140px, (min-width: 1400px) 1320px, calc(100vw - 24px)',
        loading: 'eager',
      };
      break;
  }

  return getMedia(media);
};

interface Tag {
  href: string;
  label: string;
}

function getTagLinks(tags: any[]): Tag[] {
  return tags
    .map((tag: any) => {
      const pageDelegatePath = tag.relationships?.field_page_delegate?.path?.alias ?? null;
      if (!pageDelegatePath) {
        return null;
      }

      return {
        href: pageDelegatePath,
        label: tag?.name ?? '',
      };
    })
    .filter(tag => tag !== null) as Tag[];
}

const relatedProductsColumnStyle = css`
  & > * {
    flex: 0 1 auto;
    max-height: 100%;
  }

  & > :only-child {
    align-self: flex-start;
    min-height: 0;
  }
`;

const productCardWrapperStyle = css`
  & > * {
    width: 100%;
  }
`;

const renderRelatedProducts = (relatedProducts: any[]) => {
  return (
    <Col md={3} sm={12} className={`d-flex flex-column gap-4 pb-4 ${relatedProductsColumnStyle}`}>
      {relatedProducts.slice(0, 3).map((product: any) => {
        const productNode = product;
        const productSkus = productNode?.relationships?.skus;
        const productSku = productSkus && productSkus.length > 0 ? productSkus[0].upc : '';
        const bvID = productNode?.field_bazaarvoice_id
          ? `${productNode?.field_bazaarvoice_id.slice(0, 5)}-${productNode?.field_bazaarvoice_id.slice(5)}`
          : '';
        const url = productNode?.field_alternate_url?.uri
          ? productNode.field_alternate_url.uri
          : productNode.path.alias;

        return (
          <div
            className={`d-flex flex-column w-100 align-items-start ${productCardWrapperStyle}`}
            key={productNode.path.alias}
          >
            <AnalyticsPoint
              as={ProductCard}
              key={productNode.path.alias}
              type="component"
              node={{
                ...productNode,
                name: productNode.title,
                type: productNode.internal?.type || '',
              }}
              action="view similar products"
              category="product details"
              eventLabel={productNode.title}
              typeLabel="product_related_product"
              title={productNode.title}
              image={productNode?.relationships?.image?.gatsbyImage}
              link={url}
              bvID={bvID || ''}
              sku={productSku ?? ''}
              outlinedCta
            />
          </div>
        );
      })}
    </Col>
  );
};

const ArticleTemplate: React.FC<PageProps<DataProps>> = function ({
  data: { node, relatedArticles, backfillArticles, translations },
}) {
  const { t } = useTranslation();
  const { field_media, field_tags = [], relatedProducts = [] } = node.relationships;
  const tags = getTagLinks(field_tags);
  const body = node.body?.processed ?? '';
  const mediaType: string = field_media?.internal.type || 'media__image';
  const attachedMedia = getArticleMedia(node, mediaType);
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);

  const author = node.relationships.field_author;
  const readTime = node.relationships?.field_read_time;
  const date = intlFormat(
    new Date(node.changed),
    { day: 'numeric', year: 'numeric', month: 'numeric' },
    { locale: locales[node.langcode] },
  );

  let { edges: related } = relatedArticles;
  const { edges: backfill } = backfillArticles;
  if (related.length < 3) {
    related = [...related, ...backfill].slice(0, 3);
  }
  const hasRelatedProducts = relatedProducts?.length > 0;

  return (
    <Layout
      node={node}
      metaData={node.metatag}
      language={node.langcode}
      translations={translations}
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      <AnalyticsPoint type="module" label="Article title section" typeLabel="article_title_section">
        <Container
          fluid
          className="p-6"
          style={{
            background: 'linear-gradient(180deg, #f8f8f8 0%, #f8f8f8 75%, rgba(0,0,0,0) 75%)',
          }}
        >
          <Row className="justify-content-md-center text-center mb-5">
            <Col>
              <div className="d-flex justify-content-center mt-4">
                {tags.map((tag: any, index: number) => (
                  <AnalyticsPoint
                    key={`tag-${index}`}
                    type="component"
                    label="article category"
                    typeLabel="article_category"
                    className="me-2"
                  >
                    <Link className={chip} to={tag?.href || '/'}>
                      <span className="visually-hidden">{t('Read articles about:')}</span>
                      {tag.label}
                    </Link>
                  </AnalyticsPoint>
                ))}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-md-center text-center mb-5">
            <Col xs={12} md={8}>
              <h1 className="fw-bold">{node.title}</h1>
              <AuthorContainer>
                {author && (
                  <GatsbyImage
                    image={author.relationships.image.gatsbyImage}
                    alt={author.image?.alt ?? ''}
                    objectFit="contain"
                    loading="eager"
                    style={{ width: '70px', height: '70px', borderRadius: '50%' }}
                  />
                )}
                <ArticleInfoContainer className="p-2">
                  <div>
                    {author ? (
                      <span className="fw-bold d-flex">
                        {t('By')} {author.name}
                      </span>
                    ) : null}
                    <div className="d-flex gap-2 ">
                      <Icon className="d-block mt-1 " path={mdiCalendar} size={0.8} />
                      <span className="d-flex">
                        {t('Updated')}: {date}
                      </span>
                    </div>
                  </div>
                  <div>
                    {readTime && (
                      <>
                        <DotSeparator />
                        <span>{readTime.label}</span>
                      </>
                    )}
                  </div>
                </ArticleInfoContainer>
              </AuthorContainer>
            </Col>
          </Row>

          <Row className="justify-content-md-center text-center mb-5">
            <Col xs={12} md={10} lg={6} className="pds-text-center">
              {attachedMedia}
            </Col>
          </Row>
        </Container>
      </AnalyticsPoint>
      <Container fluid="lg" className="ds-mb-5.5">
        <Container fluid className="ds-flex">
          <Row className="justify-content-md-center">
            <Col md={hasRelatedProducts ? 8 : 12} sm={12}>
              <RichText isArticle body={body} />
            </Col>
            {hasRelatedProducts ? renderRelatedProducts(relatedProducts) : null}
          </Row>
        </Container>
      </Container>
      {related.length === 3 && (
        <AnalyticsPoint
          type="module"
          typeLabel="article_related_articles"
          label="related articles"
          as={Container}
          fluid
          style={{ background: '#F3F7FF', paddingBlock: '7rem' }}
        >
          <Container>
            <h2 className="h3 fw-light mb-5">{t('Related articles')}</h2>
            <div className={relatedLayout}>
              {related.map(({ node: articleNode }: { node: IArticleContent }, index: number) => {
                return (
                  <div key={`${index}-${articleNode.id}`}>
                    <ArticleOverlayCard
                      {...getArticleCardProps(articleNode)}
                      condensed={index !== 0}
                    />
                  </div>
                );
              })}
            </div>
          </Container>
        </AnalyticsPoint>
      )}
      {/* Commenting out for reference later
      <div>
        <ArticleRelatedProducts relatedProducts={relatedProducts} />
      </div> */}
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!, $species: Int, $tags: [Int]) {
    node: nodeArticle(id: { eq: $id }, langcode: { eq: $language }) {
      ...ArticlePage
    }
    translations: allNodeArticle(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    relatedArticles: allNodeArticle(
      limit: 3
      sort: [{ sticky: DESC }, { created: DESC }]
      filter: {
        field_tags: { elemMatch: { drupal_internal__target_id: { in: $tags } } }
        id: { ne: $id }
        langcode: { eq: $language }
      }
    ) {
      edges {
        node {
          ...ArticleCard
        }
      }
    }
    backfillArticles: allNodeArticle(
      limit: 3
      filter: {
        field_species_term: { drupal_internal__target_id: { eq: $species } }
        id: { ne: $id }
        langcode: { eq: $language }
      }
    ) {
      edges {
        node {
          ...ArticleCard
        }
      }
    }
  }
`;

export default ArticleTemplate;
